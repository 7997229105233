import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {DatasetOverview} from '../_model/dataset-overview';
import {CreateOrUpdateDatasetRequest} from '../_model/create-or-update-dataset-request';

@Injectable()
export class DatasetService {

	readonly baseUrl = environment.backend_url + '/dataset';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number, searchValue: string): Observable<Page<DatasetOverview>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		return this.http.get<Page<DatasetOverview>>(this.baseUrl + '/search', {params});
	}

	uploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('uploadUuid', uploadUuid);
		return this.http.post(`${this.baseUrl}/upload`, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	createDataset(request: CreateOrUpdateDatasetRequest, uploadUuid: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/create`, request, {params: {'uploadUuid': uploadUuid}});
	}
}
