import {Component, Input, OnDestroy, OnInit, Self} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FormControl, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {Comment} from '../../_model/comment';
import {ModularFormControl} from '../../../shared/modular-forms/modular-form-control/modular-form-control';
import {UserService} from '../../../user/_service/user.service';
import {formatDate} from '@angular/common';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';

@Component({
	selector: 'app-comment-form',
	templateUrl: './comment-form.component.html',
	providers: [ModularFormsService],
	exportAs: 'commentForm'
})
export class CommentFormComponent extends ModularFormControl implements OnInit, OnDestroy {


	@Input() productionCatalogue?: ProductionCatalogue;

	isAddingComment$ = new BehaviorSubject(false);

	comments$ = new BehaviorSubject<Comment[]>([]);

	private subscription = new Subscription();

	constructor(@Self() protected formsService: ModularFormsService,
				private userService: UserService,
				private productionCatalogueService: ProductionCatalogueService) {
		super();
		this.formService.withI18nRoot('production-catalogue.form');

		this.subscription.add(this.userService.getCurrentUser().subscribe(user => {
			this.formsService.form.addControl('comment', new FormControl('', [Validators.required, Validators.minLength(1)]));
			this.formsService.form.addControl('date', new FormControl(null, Validators.required));
			this.formsService.form.addControl('user', new FormControl(user, Validators.required));
		}));

	}

	override ngOnInit(): void {
		super.ngOnInit();

		if (this.productionCatalogue) {
			this.comments$.next(this.productionCatalogue.comments);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	save(): void {

		const form = this.formsService.form;
		const comment = {
			comment: form.get('comment').value,
			timestamp: form.get('date').value,
			user: form.get('user').value
		} as Comment;
		this.comments$.value.splice(0, 0, comment);
		this.comments$.next(this.comments$.value);

		this.subscription.add(
			this.productionCatalogueService.addComment(this.productionCatalogue.uuid, {
				userUuid: comment.user.uuid,
				comment: comment.comment,
				date: comment.timestamp
			}).subscribe(() => this.isAddingComment$.next(false))
		);
	}

	cancel(): void {
		this.isAddingComment$.next(false);
	}

	addComment(): void {
		this.formService.form.get('date').setValue(formatDate(Date.now(), 'dd/MM/yyyy HH:mm', 'en'));
		this.formService.form.get('comment').setValue('');
		this.isAddingComment$.next(true);
	}

	isValid(): boolean {
		return this.formsService.form.valid;
	}

	getCommentCount(): number {
		return this.comments$.value.length;
	}
}
