import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../customer.service';
import {Page} from '../../shared/pagination/page';
import {Customer} from '../customer';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Header} from '../../shared/table/header';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../shared/modal/modal.service';
import {HttpResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProgramDescriptor} from '../../notices/program/program-descriptor';
import {CustomerFormModalComponent} from '../customer-form-modal/customer-form-modal.component';

@Component({
	selector: 'app-customer-overview',
	templateUrl: './customer-overview.component.html'
})
export class CustomerOverviewComponent implements OnInit, OnDestroy {
	page$: Observable<Page<Customer>>;
	headers: Header[] = [
		{name: 'name'},
		{name: 'icao'},
		{name: 'programs'}
	];
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	searchValue: string;

	constructor(private customerService: CustomerService,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService,
				private modalService: ModalService,
				private ngbModal: NgbModal) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([
			this.currentPage$.pipe(startWith(1)),
			this.refresh$.pipe(startWith(''))
		]).pipe(
			switchMap(([currentPage]) => this.customerService.search(currentPage - 1, this.searchValue))
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return this.customerService.export(this.searchValue);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(CustomerFormModalComponent, 'customer', true).subscribe(() => this.refresh$.next());
	}

	openUpdateModal(customer: Customer): void {
		this.modalService.openUpdateModal(CustomerFormModalComponent, customer, 'customer', true).subscribe(() => this.refresh$.next());
	}

	openViewModal(customer: Customer): void {
		const modalRef = this.ngbModal.open(CustomerFormModalComponent, {
			windowClass: 'modal-large',
			modalDialogClass: 'modal-dialog-xl'
		});
		modalRef.componentInstance.customer = customer;
		modalRef.componentInstance.readonly = true;
	}

	deleteCustomer(customer: Customer): void {
		this.subscription.add(
			this.customerService.delete(customer.uuid)
				.subscribe(() => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('customer.delete.success-message')}`
					});
					this.refresh$.next();
				}));
	}

	getProgramsCommaSeperated(programs: ProgramDescriptor[]): string {
		return programs.map(p => p.name).join(', ');
	}

	getConfirmationMessage(): string {
		return `<p class="mb-3">${this.translateService.instant('customer.delete.confirmation-message.users-will-be-deleted')}</p>`
			+ `<p>${this.translateService.instant('customer.delete.confirmation-message.are-you-sure')}</p>`;
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}
}
