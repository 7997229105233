import {Component, Input, OnInit} from '@angular/core';
import {Notice} from '../notice';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'app-view-notice-modal',
	templateUrl: './view-notice-modal.component.html',
	providers: [ModularFormsService]
})
export class ViewNoticeModalComponent implements OnInit {

	@Input() public notice: Notice;

	constructor(private formsService: ModularFormsService) {
		formsService.withI18nRoot('notice.view.modal.form');

		const form = formsService.form;
		form.addControl('availableCustomers', new FormControl([]));
		form.addControl('availablePrograms', new FormControl([]));
	}

	ngOnInit(): void {
		const customers = this.notice.customers.map(customer => customer.name);
		this.formsService.form.get('availableCustomers').setValue(customers);

		const programs = this.notice.programs.map(program => program.name);
		this.formsService.form.get('availablePrograms').setValue(programs);
	}


}
