import {Directive, inject, Input, OnInit} from '@angular/core';
import {ModularFormsService} from '../modular-forms.service';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Directive()
export abstract class ModularFormControl implements OnInit {

	@Input() public name: string;
	@Input() public i18nPrefix = '';

	formControl: FormControl;

	protected readonly formService = inject(ModularFormsService);
	protected readonly translateService = inject(TranslateService);

	ngOnInit(): void {
		this.formControl = this.formService.getControl(this.name);
	}

	public id(): string {
		const name = this.name.replaceAll('.', '-');
		return `input-${name}`;
	}

	public translate(key: string): string {
		const prefix = this.i18nPrefix ? this.i18nPrefix : this.formService.i18nPrefix;
		const name = this.name.replaceAll(/\.\d+/g, '');
		return this.translateService.instant([prefix, name, key].join('.'));
	}

	public i18nLabel(): string {
		return this.translate('label');
	}

	public i18nPlaceholder(): string {
		return this.translate('placeholder');
	}

	public i18nError(key: string): string {
		return this.translate('validation.' + key);
	}

	public i18nTooltip(): string {
		return this.translate('tooltip');
	}

	hasErrors(): boolean {
		return this.formControl.errors !== null;
	}

	protected readonly Object = Object;

}
