import {Component, Self} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Customer} from '../customer';
import {finalize, Observable, tap} from 'rxjs';
import {CreateOrUpdateCustomerRequest} from '../create-or-update-customer.request';
import {CustomerService} from '../customer.service';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {UpdateForm} from '../../shared/modal/update.form';

@Component({
	selector: 'app-customer-form-modal',
	templateUrl: './customer-form-modal.component.html',
	providers: [ModularFormsService]
})
export class CustomerFormModalComponent implements UpdateForm<Customer> {

	customer: Partial<Customer>;
	readonly: boolean;
	submitting = false;

	constructor(@Self() protected formService: ModularFormsService,
				private activeModal: NgbActiveModal,
				private customerService: CustomerService) {
	}

	setContent(content: Customer): void {
		this.customer = content;
	}

	get i18nTitle(): string {
		if (this.readonly) {
			return 'customer.view.modal.title';
		}
		return this.customer ? 'customer.update.modal.title' : 'customer.create.modal.title';
	}

	get i18nSubmit(): string {
		if (this.readonly) {
			return 'general.close';
		}
		return this.customer ? 'general.save' : 'general.create';
	}

	submitForm(): void {
		this.doSubmit().subscribe();
	}

	private doSubmit(): Observable<Customer> {
		this.submitting = true;

		const formValue = this.formService.form.value;
		formValue['programUuids'] = formValue.programs.map((option: any) => option.id);
		delete formValue['programs'];

		const customerRequest: CreateOrUpdateCustomerRequest = formValue;

		return this.submit(customerRequest).pipe(
			tap(() => {
				this.activeModal.close(true);
			}),
			finalize(() => this.submitting = false)
		);
	}

	private submit(request: CreateOrUpdateCustomerRequest): Observable<Customer> {
		if (this.customer) {
			return this.customerService.update(this.customer.uuid, request);
		}
		return this.customerService.create(request);
	}
}
