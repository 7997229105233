<form [formGroup]="formService.form">

	<app-modular-form-control-input name="userName"></app-modular-form-control-input>

	<app-modular-form-control-input name="firstName"></app-modular-form-control-input>

	<app-modular-form-control-input name="lastName"></app-modular-form-control-input>

	<app-modular-form-control-input name="email"></app-modular-form-control-input>

	<app-modular-form-control-select name="role"
									 [options]="roles$ | async"></app-modular-form-control-select>

	<app-modular-form-control-multiselect name="projects"
										  *ngIf="isProductionRoleSelected()"
										  [options]="projects$ | async"></app-modular-form-control-multiselect>

	<app-modular-form-control-select name="customer"
									 *ngIf="requireOptionalFields()"
									 [options]="customers$ | async"></app-modular-form-control-select>

	<app-modular-form-control-select name="type"
									 *ngIf="requireOptionalFields()"
									 [options]="types"></app-modular-form-control-select>

	<app-invalid-feedback></app-invalid-feedback>
</form>

