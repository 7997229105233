<app-base-modal [title]="i18nTitle | translate" [disabled]="submitting">
	<app-customer-form [customer]="customer" (apply)="submitForm()" [readonly]="readonly || submitting"/>
	<ng-container actions *ngIf="!readonly">
		<button id="submit-customer" type="button" class="btn btn-primary"
				(click)="submitForm()"
				[disabled]="formService.form.invalid || submitting">
			{{ i18nSubmit | translate }}
		</button>
	</ng-container>
</app-base-modal>

