import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AutoLoginService} from './auto.login.service';

@Component({
	selector: 'app-role-based-landing',
	template: ''
})
export class RoleBasedLandingComponent implements OnInit, OnDestroy {

	subscription = new Subscription();

	constructor(private autoLoginService: AutoLoginService) {
	}

	ngOnInit(): void {
		console.log('callback init');
		this.subscription.add(this.autoLoginService.redirectUserToCorrectLandingPage().subscribe());
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

}
