<form [formGroup]="formService.form" (ngSubmit)="this.submitForm()">

	<app-modular-form-control-input name="name"/>

	<app-modular-form-control-input name="icao"/>

	<app-customer-subscription-form #distributionSubscriptions name="distributionSubscriptions" [offset]="true"/>

	<br>
	<app-customer-subscription-form #viewerSubscriptions name="viewerSubscriptions"/>

	<br>
	<app-modular-form-control-multiselect name="programs"
										  [options]="programs$ | async"/>

	<app-modular-form-control-checkbox name="suspended"/>

	<app-invalid-feedback></app-invalid-feedback>
</form>

