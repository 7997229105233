import {Injectable} from '@angular/core';
import {Observable, of, switchMap, tap} from 'rxjs';
import {AuthenticatedResult} from 'angular-auth-oidc-client/lib/auth-state/auth-result';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {UserService} from '../user/_service/user.service';
import {User} from '../user/_model/user';

@Injectable()
export class AutoLoginService {

	constructor(private userService: UserService,
				private oidcSecurityService: OidcSecurityService,
				private router: Router) {
	}

	redirectUserToCorrectLandingPage(): Observable<any> {
		return this.oidcSecurityService.isAuthenticated$.pipe(
			switchMap((result: AuthenticatedResult) => {
				if (result.isAuthenticated) {
					return this.userService.getCurrentUser();
				}
				console.log('Not authenticated: ' + result);
				return of(null);
			}),
			tap({
				next: (user: User) => {
					if (user) {
						this.router.navigate([user.role.landingPage]);
					} else {
						console.log('No User');
					}
				},
				error: () => {
					this.router.navigate(['no-access']);
				}
			})
		);
	}
}
