import {Component, Input} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {ArticleService} from '../../../article/_service/article.service';
import {map, Observable, shareReplay} from 'rxjs';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {ModularFormControl} from '../../../shared/modular-forms/modular-form-control/modular-form-control';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {mapArticleDescriptors} from '../../../shared/modular-forms/_model/select-option.factory';

@Component({
	selector: 'app-customer-subscription-form',
	templateUrl: './customer-subscription-form.component.html'
})
export class CustomerSubscriptionFormComponent extends ModularFormControl {

	@Input() public offset: boolean;

	articles$: Observable<SelectOption[]>;

	constructor(private articleService: ArticleService,
				private formsService: ModularFormsService) {
		super();

		this.articles$ = this.articleService.findAll().pipe(
			mapArticleDescriptors(),
			shareReplay());
	}

	getArticles(index: number): Observable<SelectOption[]> {
		const uuids = this.formsService.getControl(this.name).getRawValue().map((sub: any) => sub.articleUuid);
		delete uuids[index];
		return this.articles$.pipe(map(articles => articles.filter(article => !uuids.includes(article.value))));
	}

	getControls(): AbstractControl[] {
		return this.formService.getControl<FormArray>(this.name).controls;
	}

	getSubscriptionCount(): number {
		return this.getControls().length;
	}

	addSubscription(): void {
		const controls: { [key: string]: FormControl } = {};
		controls['articleUuid'] = new FormControl(null);
		controls['startDate'] = new FormControl(null);
		controls['endDate'] = new FormControl(null);

		if (this.offset) {
			controls['offset'] = new FormControl(-7);
		}

		const group = new FormGroup(controls);
		if (this.formsService.form.disabled) {
			group.disable();
		}

		this.formsService.getControl<FormArray>(this.name).push(group);
	}

	removeSubscription(index: number): void {
		this.getControls().splice(index, 1);
	}

}
