<app-base-modal [title]="i18nTitle | translate" [disabled]="submitting">
	<app-project-form [project]="project" (apply)="submitForm()" [readonly]="submitting"/>
	<ng-container actions>
		<button id="submit-project" type="button" class="btn btn-primary"
				(click)="submitForm()"
				[disabled]="formsService.form.invalid || submitting">
			{{ i18nSubmit | translate }}
		</button>
	</ng-container>
</app-base-modal>
