import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, Self} from '@angular/core';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, map, Observable, shareReplay, Subscription} from 'rxjs';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {CustomerService} from '../../customer/customer.service';
import {FlightDatabaseService} from '../../db/_service/flight-database.service';
import {ProgramService} from '../../notices/_service/program.service';
import {PermissionService} from '../../shared/permission/permission.service';
import {FlightManagementSystemService} from '../../flight-management-system/_service/flight-management-system.service';
import {FlightManagementSystem} from '../../flight-management-system/_model/flight-management-system';
import {GeoAreaService} from '../_service/geo-area.service';
import {exportControlOptions, mapGeoAreas, mapGeoAreaTypes, mapPrograms, mapProjects, mapRadicalIdentifiers, rnpArOptions} from '../../shared/modular-forms/_model/select-option.factory';
import {Article} from '../_model/article';
import {ProjectService} from '../../project/_service/project.service';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {RadicalIdentifierService} from '../../radicalidentifier/_service/radical-identifier.service';

class Visible {
	[id: string]: boolean
}

@Component({
	selector: 'app-article-form',
	templateUrl: './article-form.component.html',
	providers: [ModularFormsService],
	exportAs: 'articleForm',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleFormComponent implements OnInit, OnChanges, OnDestroy {

	@Input()
	public article: Article;

	@Input()
	public readonly = false;

	programs$: Observable<SelectOption[]>;
	flightManagementSystems$: Observable<SelectOption[]>;
	geoAreasTypes$: Observable<SelectOption[]>;
	geoAreas$: Observable<{ [key: string]: SelectOption[] }>;
	projects$: Observable<SelectOption[]>;
	radicalIdentifiers$: Observable<SelectOption[]>;

	canRequestImmediately$: Observable<boolean>;
	visible = new Visible();

	rnpArs: SelectOption[];
	exportControls: SelectOption[];

	private subscription = new Subscription();

	constructor(@Self() protected formService: ModularFormsService,
				private programService: ProgramService,
				private customerService: CustomerService,
				private flightDatabaseService: FlightDatabaseService,
				private permissionService: PermissionService,
				private geoAreaService: GeoAreaService,
				private radicalIdentifierService: RadicalIdentifierService,
				private flightManagementSystemService: FlightManagementSystemService,
				private projectService: ProjectService) {
		this.visible['viewer'] = false;

		this.programs$ = this.programService.findAll()
			.pipe(
				mapPrograms(),
				shareReplay()
			);

		this.geoAreasTypes$ = this.geoAreaService.getAll().pipe(
			mapGeoAreaTypes(),
			shareReplay()
		);

		this.geoAreas$ = this.geoAreaService.getAll().pipe(
			mapGeoAreas(),
			shareReplay()
		);

		this.flightManagementSystems$ = this.flightManagementSystemService.getAll().pipe(
			map(systems => systems.map(fms => ({value: fms, label: fms.name, id: fms.identifier}))),
			shareReplay()
		);

		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		this.radicalIdentifiers$ = this.radicalIdentifierService.findAll().pipe(
			mapRadicalIdentifiers(),
			shareReplay()
		);

		this.rnpArs = rnpArOptions();
		this.exportControls = exportControlOptions();

		this.formService.withI18nRoot('article.form');

		const form = this.formService.form;
		form.addControl('reference', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		form.addControl('usualDesignation', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		form.addControl('type', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		form.addControl('programs', new FormControl([], [Validators.required]));
		form.addControl('offset', new FormControl(0, [Validators.required]));
		form.addControl('customers', new FormControl([] as string[]));
		form.addControl('flightDatabases', new FormControl([] as string[]));

		// production parameters
		form.addControl('flightManagementSystem', new FormControl(null));
		form.addControl('dqrReference', new FormControl({value: '', disabled: true}));
		form.addControl('compatibleApp', new FormControl({value: '', disabled: true}));
		form.addControl('tailored', new FormControl(false));
		form.addControl('tailoredCode', new FormControl(undefined, [Validators.pattern(/^.{3}$/)]));
		form.addControl('geoAreaType', new FormControl(null));
		form.addControl('geoAreas', new FormControl(null));
		form.addControl('projects', new FormControl(null));
		form.addControl('radicalIdentifierUuid', new FormControl(null));
		form.addControl('rnpAr', new FormControl(null));
		form.addControl('exportControl', new FormControl(null));
		form.addControl('lvp', new FormControl(null));


		this.subscription.add(this.projects$.subscribe(projects => {
			if (projects.length === 1) {
				form.get('projects').patchValue(projects[0]);
			}
		}));


		this.subscription.add(form.get('geoAreaType').valueChanges
			.pipe(distinctUntilChanged())
			.subscribe((value: string) => {
				if (value === 'WORLDWIDE') {
					form.get('geoAreas').setValue(value);
				} else {
					form.get('geoAreas').setValue(null);
				}
			}));

		this.subscription.add(form.get('flightManagementSystem').valueChanges.subscribe((value: FlightManagementSystem) => {
			if (value) {
				const dqrControl = form.get('dqrReference');
				dqrControl.setValue(value.dqrReference);
				dqrControl.disable();

				const compatibleAppControl = form.get('compatibleApp');
				compatibleAppControl.setValue(value.compatibleApp);
				compatibleAppControl.disable();
			}
		}));

		this.subscription.add(form.get('tailored').valueChanges.subscribe(value => {
			if (!value) {
				form.get('tailoredCode').patchValue(undefined);
				form.get('tailoredCode').updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		this.canRequestImmediately$ = this.permissionService.hasAtLeastOnePermission(['REQUEST_ARTICLES_IMMEDIATELY']);
		this.form.patchValue(this.article);
		if (this.article) {
			if (this.article.tailoredCode) {
				this.form.get('tailored').patchValue(true);
			}

			if (!this.article.canEditArticleReference) {
				this.form.get('reference').disable();
			}

			this.subscription.add(this.hasViewProductionParametersPermission().subscribe((viewProductionParameters) => {
				if (viewProductionParameters && !this.article.canEditProductionParameters) {
					this.form.get('flightManagementSystem').disable();
					this.form.get('dqrReference').disable();
					this.form.get('compatibleApp').disable();
					this.form.get('tailored').disable();
					this.form.get('tailoredCode').disable();
					this.form.get('geoAreaType').disable();
					this.form.get('geoAreas').disable();
					this.form.get('projects').disable();
					this.form.get('radicalIdentifierUuid').disable();
					this.form.get('rnpAr').disable();
					this.form.get('exportControl').disable();
					this.form.get('lvp').disable();
				}

				if(!this.article.canEditProductionParameters) {
					this.form.get('type').disable();
					this.form.get('programs').disable();
				}
			}));

			this.setSelectedGeoAreas();
			this.setSelectedPrograms();
			this.setSubscribedCustomers();
			this.setLinkedFlightDatabases();
			this.setSelectedFlightManagementSystems();
			this.setSelectedProjects();
			this.setSelectedRadicalIdentifier();
		} else {
			this.subscription.add(this.projects$.subscribe(projects => {
				if (projects.length === 1) {
					this.formService.getControl('projects').patchValue(projects);
					this.formService.getControl('projects').disable();
				}
			}));
		}
		this.subscribeToFormValueChanges();
	}

	private setSelectedProjects(): void {
		const projectUuids = this.article.projects.map(project => project.uuid);
		this.projects$.pipe(
			map(projects => projects.filter(project => projectUuids.indexOf(project.id) >= 0))
		)
			.subscribe(selectedProjects => this.formService.getControl('projects').patchValue(selectedProjects));
	}

	private setSelectedFlightManagementSystems(): void {
		this.flightManagementSystems$
			.pipe(map(options => options.find(option => option.id === this.article.flightManagementSystemIdentifier)))
			.subscribe(option => {
				if (option) {
					this.form.patchValue({flightManagementSystem: option.value});
				}
			});
	}

	private setSelectedRadicalIdentifier(): void {
		this.radicalIdentifiers$
			.pipe(map(options => options.find(option => option.id === this.article.radicalIdentifierUuid)))
			.subscribe(option => {
				if (option) {
					this.form.patchValue({radicalIdentifier: option.value});
				}
			});
	}

	private setSelectedGeoAreas(): void {
		if (this.article.geoAreas?.length > 0) {
			this.subscription.add(this.geoAreas$.subscribe(areaMap => {
				const type = Object.keys(areaMap).find(key => areaMap[key].map(option => option.value).includes(this.article.geoAreas[0]));
				this.form.get('geoAreaType').setValue(type);

				const areaOptions = this.article.geoAreas.map(area => areaMap[type].find(ga => ga.value === area));
				if (type !== 'REGIONAL') {
					this.form.get('geoAreas').setValue(areaOptions[0].value);
				} else {
					this.form.get('geoAreas').setValue(areaOptions);
				}
			}));
		} else {
			this.form.get('geoAreas').setValue(null);
		}
	}

	private setLinkedFlightDatabases(): void {
		this.subscription.add(this.flightDatabaseService.getLinkedFlightDatabases(this.article.uuid)
			.subscribe(flightDatabases => {
				const flightDatabasesNames = flightDatabases.map(flightDatabase => flightDatabase.databaseName);
				this.formService.getControl('flightDatabases').setValue(flightDatabasesNames);
			}));
	}

	private setSubscribedCustomers(): void {
		this.subscription.add(this.customerService.findActiveSubscribedCustomersForArticle(this.article.uuid)
			.subscribe(customers => {
				const customersNames = customers.map(customer => customer.name);
				this.formService.getControl('customers').setValue(customersNames);
			}));
	}

	private setSelectedPrograms(): void {
		const programUuids = this.article.programs.map(program => program.uuid);
		this.programs$.pipe(
			map(programs => programs.filter(program => programUuids.indexOf(program.id) >= 0))
		)
			.subscribe(selectedPrograms => this.formService.getControl('programs').patchValue(selectedPrograms));
	}

	private subscribeToFormValueChanges(): void {
		this.subscription.add(
			this.form.valueChanges.subscribe(() => {
				if (this.form.controls['tailored'].value
					|| this.form.controls['geoAreas']?.value
					|| this.form.controls['geoAreaType'].value
					|| this.form.controls['flightManagementSystem'].value
					|| this.form.controls['projects']?.value) {

					this.form.controls['reference'].clearValidators();
				} else {
					this.form.controls['geoAreaType'].clearValidators();
					this.form.controls['geoAreas'].clearValidators();
					this.form.controls['flightManagementSystem'].clearValidators();
					this.form.controls['projects'].clearValidators();
					this.form.controls['tailoredCode'].clearValidators();
				}

				if (this.form.controls['tailored'].value == true) {
					this.form.controls['tailoredCode'].addValidators([Validators.required, Validators.pattern(/^.{3}$/)]);
				} else {
					this.form.controls['tailoredCode'].clearValidators();
				}
			}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnChanges(): void {
		if (this.readonly) {
			this.form.disable();
		} else {
			this.form.enable();
		}

		if (this.form.controls['tailored'].value == false) {
			this.form.controls['tailoredCode'].patchValue(undefined);
		}
	}

	getControlValue(name: string): any {
		return this.formService.getControl(name).value;
	}

	noProductionParametersGiven(): boolean {
		this.form.controls['tailoredCode'].updateValueAndValidity();
		this.form.controls['geoAreas'].updateValueAndValidity();
		this.form.controls['geoAreaType'].updateValueAndValidity();
		this.form.controls['reference'].updateValueAndValidity();
		this.form.controls['flightManagementSystem'].updateValueAndValidity();
		this.form.controls['projects'].updateValueAndValidity();
		this.form.controls['radicalIdentifierUuid'].updateValueAndValidity();
		this.form.controls['rnpAr'].updateValueAndValidity();
		this.form.controls['exportControl'].updateValueAndValidity();
		return !this.form.controls['tailored'].value
			&& !this.form.controls['geoAreas'].value
			&& !this.form.controls['geoAreaType'].value
			&& !this.form.controls['flightManagementSystem'].value
			&& !this.form.controls['projects'].value
			&& !this.form.controls['radicalIdentifierUuid'].value
			&& !this.form.controls['rnpAr'].value
			&& !this.form.controls['exportControl'].value;
	}

	public isInvalid(): boolean {
		return this.formService.form.invalid;
	}

	getArticle(): CreateOrUpdateArticleRequest {
		const controls = this.formService.form.controls;
		const request: CreateOrUpdateArticleRequest = {
			programUuids: controls['programs'].value.map((p: SelectOption) => p.id),
			reference: controls['reference'].value,
			tailoredCode: controls['tailoredCode']?.value,
			usualDesignation: controls['usualDesignation']?.value,
			type: controls['type']?.value,
			offset: controls['offset']?.value,
			flightManagementSystemIdentifier: (controls['flightManagementSystem']?.value as FlightManagementSystem)?.identifier,
			radicalIdentifierUuid: controls['radicalIdentifierUuid']?.value,
			rnpAr: controls['rnpAr']?.value,
			exportControl: controls['exportControl']?.value,
			lvp: controls['lvp'].value
		};

		if (controls['geoAreaType'].value === 'REGIONAL') {
			request.geoAreas = controls['geoAreas'].value.map((ga: SelectOption) => ga.id);
		} else if (controls['geoAreaType']) {
			request.geoAreas = [controls['geoAreas'].value];
		}


		request.programUuids = controls['programs'].value.map((p: SelectOption) => p.id);
		request.projectUuids = controls['projects'].value?.map((p: SelectOption) => p.id);
		if (this.formService.getControl('projects').disabled && this.formService.getControl('projects').value) {
			request.projectUuids = this.formService.getControl('projects').value.map((p: SelectOption) => p.id);
		}
		return request;
	}

	toggleCollapse(key: string): void {
		this.visible[key] = !this.visible[key];
	}

	get form(): FormGroup {
		return this.formService.form;
	}

	hasViewProductionParametersPermission(): Observable<boolean> {
		return this.permissionService.hasAtLeastOnePermission(['VIEW_ARTICLE_PRODUCTION_PARAMETERS']);
	}

}
