import {NgModule} from '@angular/core';
import {DirectDownloadComponent} from './direct-download.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
	declarations: [
		DirectDownloadComponent
	],
	imports: [
		TranslateModule
	],
	exports: [
		DirectDownloadComponent
	]
})
export class DownloadModule {
}
