import {Component, OnDestroy, Self} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RadicalIdentifier} from '../_model/radical-identifier';
import {RadicalIdentifierService} from '../_service/radical-identifier.service';
import {UpdateForm} from '../../shared/modal/update.form';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {CreateOrUpdateRadicalIdentifierRequest} from '../_model/create.or.update.radical.identifier.request';

@Component({
	selector: 'app-radical-identifier-modal',
	templateUrl: './radical-identifier-form-modal.component.html',
	providers: [ModularFormsService]
})
export class RadicalIdentifierFormModalComponent implements OnDestroy, UpdateForm<RadicalIdentifier> {

	submitting = false;
	radicalIdentifier: RadicalIdentifier;
	private subscription = new Subscription();

	constructor(@Self() protected formService: ModularFormsService,
				public activeModal: NgbActiveModal,
				private formBuilder: FormBuilder,
				private radicalIdentifierService: RadicalIdentifierService
	) {
		this.formService.withI18nRoot('radical-identifier.form');

		const form = this.formService.form;

		form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(255)]));
	}

	get form(): FormGroup {
		return this.formService.form;
	}

	setContent(content: RadicalIdentifier): void {
		this.radicalIdentifier = content;
		this.form.patchValue(this.radicalIdentifier);
	}

	getControlValue(name: string): FormControl<any>  {
		return this.formService.getControl(name).value;
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	save(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(this.doSave()
			.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe((radicalIdentifier) => {
				this.activeModal.close(radicalIdentifier);
			})
		);
	}

	doSave(): Observable<RadicalIdentifier> {
		if (!this.radicalIdentifier) {
			return this.radicalIdentifierService.create(this.createRequest());
		} else {
			return this.radicalIdentifierService.update(this.radicalIdentifier.uuid, this.createRequest());
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private createRequest(): CreateOrUpdateRadicalIdentifierRequest {
		return {
			name: this.form.get('name').value,
			usualDesignation: this.form.get('usualDesignation').value
		};
	}
}
