<ng-template #content>
	<ng-content/>
</ng-template>

<ng-container *ngIf="!parent.layout; else withLayout">
	<ng-container *ngTemplateOutlet="content"/>
</ng-container>

<ng-template #withLayout>
	<ng-container *ngIf="parent.layout === 'stack'">
		<div class="layout-wrapper mb-4">
			<ng-container *ngTemplateOutlet="labelWrapper"/>
			<ng-container *ngTemplateOutlet="inputWrapper"/>
		</div>
	</ng-container>

	<ng-container *ngIf="parent.layout === 'stack-inline'">
		<div class="layout-wrapper mb-4 stack-inline">
			<ng-container *ngTemplateOutlet="labelWrapper"/>
			<ng-container *ngTemplateOutlet="inputWrapper"/>
		</div>
	</ng-container>

	<ng-container *ngIf="parent.layout === 'flex'">
		<div class="layout-wrapper d-flex justify-content-between gap-2 mb-4">
			<ng-container *ngTemplateOutlet="labelWrapper"/>
			<ng-container *ngTemplateOutlet="inputWrapper"/>
		</div>
	</ng-container>

	<ng-container *ngIf="parent.layout === 'flex-inline'">
		<div class="layout-wrapper d-flex justify-content-between gap-2 flex-inline">
			<ng-container *ngTemplateOutlet="labelWrapper"/>
			<ng-container *ngTemplateOutlet="inputWrapper"/>
		</div>
	</ng-container>
</ng-template>

<ng-template #labelWrapper>
	<div class="label-wrapper d-flex align-items-center" *ngIf="!parent.hideLabel">
		<label [for]="id()" class="form-label"
			   [class.mb-0]="parent.layout === 'flex' || parent.layout === 'flex-inline'">{{ i18nLabel() }}</label>
		<i *ngIf="parent.showTooltip" id="{{id() + '-tooltip'}}"
		   class="ti ti-alert-circle fs-5 mx-1"
		   [ngbTooltip]="i18nTooltip()"></i>
	</div>
</ng-template>

<ng-template #inputWrapper>
	<div class="input-wrapper">
		<ng-container *ngTemplateOutlet="content"/>

		<div class="invalid-feedback" *ngIf="formControl.errors">
            <span *ngFor="let error of Object.keys(formControl.errors)">
                {{ i18nError(error) }}
            </span>
		</div>
	</div>
</ng-template>
