import {Component, OnInit} from '@angular/core';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';
import {BehaviorSubject, combineLatest, map, Observable, of, startWith, Subject, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {ProductionCatalogueOverview} from '../_model/production-catalogue-overview';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {formatDate} from '@angular/common';
import {ContentArray} from '../../shared/model/content-array';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {DropdownDataFactory} from '../../shared/dropdown-multiselect/dropdown-data.factory';

type STATE = 'CREATING' | 'UPDATING' | 'VIEWING' | 'COPYING' | 'OVERVIEW';

@Component({
	selector: 'app-production-catalogue-overview',
	templateUrl: './production-catalogue-overview.component.html',
	styleUrls: ['./production-catalogue-overview.component.css']
})
export class ProductionCatalogueOverviewComponent implements OnInit {
	state$ = new BehaviorSubject<STATE>('OVERVIEW');
	productionCatalogue$: Observable<ProductionCatalogue>;
	page$: Observable<Page<ProductionCatalogueOverview>>;
	headers: Header[] = [
		{name: 'status'},
		{name: 'approvalDate'},
		{name: 'identification'},
		{name: 'airac'},
		{name: 'requester'},
		{name: 'project'},
		{name: 'nrOfArticles'},
		{name: 'nrOfLinkedDatabases'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();
	selectedFilterCriteria = {} as ContentArray;
	searchValue = '';
	filterDropdownData$: Observable<DropdownData[]>;

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly dropdownDataFactory: DropdownDataFactory,
				private readonly translateService: TranslateService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.productionCatalogueService.search(currentPage - 1, this.selectedFilterCriteria, this.searchValue))
			);

		this.filterDropdownData$ = this.refresh$.pipe(
			startWith(''),
			switchMap(() => this.productionCatalogueService.getFilterCriteria()),
			map(filterCriteria => filterCriteria.map(criteria => this.dropdownDataFactory.createDropdownData(criteria)))
		);
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreatePage(): void {
		this.state$.next('CREATING');
	}

	closePage(): void {
		this.state$.next('OVERVIEW');
	}

	createCatalogue(request: CreateProductionCatalogueRequest): void {
		this.productionCatalogueService.create(request).subscribe((productionCatalogue: ProductionCatalogue) => {
			this.state$.next('OVERVIEW');
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.create.success-message')}`
			});
			this.openViewPage(productionCatalogue.uuid);
		});
	}

	updateCatalogue(request: UpdateProductionCatalogueRequest): void {
		this.productionCatalogueService.update(request.uuid, request).subscribe(() => {
			this.state$.next('OVERVIEW');
			this.toastrMessageService.show({
				type: ToastrMessageType.SUCCESS,
				messageHTML: `<i class="ti ti-check me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.edit.success-message')}`
			});
			this.refresh$.next();
		});
	}

	deleteCatalogue(uuid: string): void {
		this.productionCatalogueService.delete(uuid)
			.subscribe({
				next: () => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.delete.success-message')}`
					});
					this.refresh$.next();
					this.state$.next('OVERVIEW');
				},
				error: (errorResponse) => {
					this.toastrMessageService.show({
						type: ToastrMessageType.ERROR,
						messageHTML: errorResponse.error.errorCode
					});
				}
			});
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('production-catalogue.delete.confirmation-message.are-you-sure')}</p>`;
	}

	openViewPage(uuid: string): void {
		this.productionCatalogueService.get(uuid).subscribe((prodCat: ProductionCatalogue) => {
			this.productionCatalogue$ = of(prodCat);
			this.state$.next('VIEWING');
		});
	}

	openEditPage(uuid: string): void {
		this.productionCatalogueService.get(uuid).subscribe((prodCat: ProductionCatalogue) => {
			this.productionCatalogue$ = of(prodCat);
			this.state$.next('UPDATING');
		});
	}

	openCopyPage(uuid: string): void {
		this.productionCatalogueService.get(uuid).subscribe((prodCat: ProductionCatalogue) => {
			prodCat.uuid = undefined;
			prodCat.status = {status: 'REDACTION_IN_PROGRESS', nextStatuses: []};
			prodCat.approvalDate = undefined;
			prodCat.identification = this.translateService.instant('general.copy-of') + prodCat.identification;
			this.productionCatalogue$ = of(prodCat);
			this.state$.next('COPYING');
		});
	}

	exportProductionCatalogue(productionCatalogue: ProductionCatalogueOverview): void {
		const subscription = this.productionCatalogueService.export(productionCatalogue.uuid).subscribe(response => {
			const a = document.createElement('a');
			a.download = productionCatalogue.identification + '_' + formatDate(Date.now(), 'yyyy-MM-dd_HH-mm-ss', 'en') + '.pdf';
			a.href = window.URL.createObjectURL(response.body);
			a.click();
			subscription.unsubscribe();
		});
	}

	search(searchValue: any): void {
		this.searchValue = searchValue;
		this.loadPage(1);
	}

	filtersSelected(event: ContentArray): void {
		this.selectedFilterCriteria = {};
		Object.keys(event).forEach(key => this.selectedFilterCriteria[key] = event[key]);
		this.loadPage(1);
	}

	hasSearchCriteria(): boolean {
		return !!this.searchValue || Object.keys(this.selectedFilterCriteria).length > 0;
	}
}
