import {Component} from '@angular/core';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {UploadStep} from '../../shared/upload/upload-step';

@Component({
	selector: 'app-fms-upload',
	templateUrl: './fms-upload.component.html',
	providers: [ModularFormsService]
})
export class FmsUploadComponent {

	step: UploadStep = 'INPUT';

	constructor(private formService: ModularFormsService) {
		this.formService.withI18nRoot('fms.upload.form');
	}

	public submit(): void {
		this.step = 'PROCESSING';
	}

	public reset(): void {
		this.step = 'INPUT';
		this.formService.form.reset();
	}

}
