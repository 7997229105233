import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UpdateForm} from '../../shared/modal/update.form';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, Observable, tap} from 'rxjs';
import {CreateOrUpdateArticleRequest} from '../_model/create-or-update-article-request';
import {ArticleService} from '../_service/article.service';
import {Article} from '../_model/article';

@Component({
	selector: 'app-create-or-update-article-modal',
	templateUrl: './create-or-update-article.modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOrUpdateArticleModalComponent implements UpdateForm<Article> {

	article: Article;
	submitting = false;

	constructor(private activeModal: NgbActiveModal,
							private articleService: ArticleService) {
	}

	setContent(content: Article): void {
		this.article = content;
	}

	getTitleTranslationKey(): string {
		return this.article ? 'article.update.modal.title' : 'article.create.modal.title';
	}

	getSubmitTranslationKey(): string {
		return this.article ? 'general.save' : 'general.create';
	}

	submitForm(article: CreateOrUpdateArticleRequest): void {
		this.doSubmit(article).subscribe();
	}

	private doSubmit(article: CreateOrUpdateArticleRequest): Observable<void> {
		this.submitting = true;

		return this.submit(article)
			.pipe(
				tap(() => {
					this.submitting = false;
					this.activeModal.close(true);
				}),
				finalize(() => this.submitting = false)
			);
	}

	private submit(request: CreateOrUpdateArticleRequest): Observable<void> {
		if (this.article) {
			return this.articleService.update(this.article.uuid, request);
		}
		return this.articleService.create(request);
	}
}
