<app-base-modal [title]="'notice.view.modal.title' | translate">

	<div *ngIf="notice.allCustomers" class="cursor-default form-label">
		{{ 'notice.view.modal.form.allCustomers' | translate }}
	</div>

	<div *ngIf="!notice.allCustomers && notice.customers.length > 0">
		<app-modular-form-control-static-list name="availableCustomers"/>
	</div>

	<div *ngIf="!notice.allCustomers && notice.programs.length > 0">
		<app-modular-form-control-static-list name="availablePrograms"/>
	</div>

	<div *ngIf="notice.customersNotifiedOn">
		<div class="d-flex align-items-center justify-content-between">
			<label [for]="'customers-notified-on'" class="form-label"
				   [class.mb-0]="'flex-inline'">{{ 'notice.view.modal.customers-notified' | translate }}</label>

			<div class="d-flex flex-wrap" [id]="'customers-notified-on'">
				<span class="badge list-group-item-info cursor-default me-2 mb-1" style="min-width: 100px">
					{{ notice.customersNotifiedOn ? notice.customersNotifiedOn : '-' }}
				</span>
			</div>
		</div>
	</div>
</app-base-modal>
