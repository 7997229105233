import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../shared/modal/modal.service';

@Component({
	selector: 'app-production-catalogue-form-page',
	templateUrl: './production-catalogue-form-page.component.html'
})
export class ProductionCatalogueFormPageComponent {

	@Input() productionCatalogue: ProductionCatalogue;
	@Input() copy = false;

	@Output()
	public closePage: EventEmitter<void> = new EventEmitter();

	@Output()
	public createCatalogue: EventEmitter<CreateProductionCatalogueRequest> = new EventEmitter();

	@Output()
	public updateCatalogue: EventEmitter<UpdateProductionCatalogueRequest> = new EventEmitter();


	constructor(private translateService: TranslateService, private modalService: ModalService) {
	}
	close(): void {
		this.closePage.emit();
	}

	submitForm(form: FormGroup, oldStatus: string): void {
		if (this.productionCatalogue && !this.copy) {
			if (oldStatus !== form.get('status').value) {
				this.modalService.showConfirmationModal({
					cancelAction: new EventEmitter<void>(), cancelButtonText: 'general.cancel', confirmButtonText: 'general.confirm', danger: true, hasFooter: true, modalIcon: 'ti-alert-circle',
					confirmationMessage: this.translateService.instant('production-catalogue.status-change.confirmation-message.are-you-sure'),
					confirmAction: new EventEmitter<void>(),
					confirmFunction: ()=> this.updateCatalogue.emit(this.getUpdateProductionCatalogueRequest(form))
				});
			} else {
				this.updateCatalogue.emit(this.getUpdateProductionCatalogueRequest(form));
			}
		} else {
			this.createCatalogue.emit(this.getCreateProductionCatalogueRequest(form));
		}
	}

	get i18nSubmit(): string {
		if (this.copy) {
			return 'general.copy';
		}
		return this.productionCatalogue ? 'general.save' : 'general.create';
	}

	get titleTranslationKey(): string {
		return this.productionCatalogue ? 'production-catalogue.edit.title' : 'production-catalogue.form.title';
	}

	getCreateProductionCatalogueRequest(form: FormGroup): CreateProductionCatalogueRequest {
		const articles = form.get('articles').value;

		articles.forEach((article: any) => {
			if (typeof article.productionDate !== 'string') {
				const day = String(article.productionDate['day']).padStart(2, '0');
				const month = String(article.productionDate['month']).padStart(2, '0');
				const year = article.productionDate['year'];
				article.productionDate = year + '-' + month + '-' + day;
			}
		});

		return {
			airacCycle: form.get('airacCycle').value,
			articleProductions: articles,
			identification: form.get('identification').value,
			projectUuid: form.get('project').value,
			requesterUserUuid: form.get('requesterUserUuid').value
		};
	}

	getUpdateProductionCatalogueRequest(form: FormGroup): UpdateProductionCatalogueRequest {
		return {
			uuid: this.productionCatalogue.uuid,
			identification: form.get('identification').value,
			status: form.get('status').value,
			airacCycle: form.get('airacCycle').value,
			projectUuid: form.get('project').value,
			articleProductions: form.get('articles').value
		};
	}

}
