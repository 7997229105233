import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModularFormsService} from '../../../../shared/modular-forms/modular-forms.service';
import {BaseUploadComponent} from '../../../../shared/upload/base-upload.component';
import {CustomValidators} from '../../../../shared/validators/custom-validators';

@Component({
	selector: 'app-db-upload-form',
	templateUrl: './upload-form.component.html'
})
export class UploadFormComponent {

	constructor(protected formService: ModularFormsService) {
		const form = this.formService.form;

		form.addControl('articleReference', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		form.addControl('databaseName', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		form.addControl('releaseNumber', new FormControl('', [Validators.required, Validators.maxLength(20)]));
		form.addControl('airacCycle', new FormControl('', [Validators.required, CustomValidators.validAirac]));
		form.addControl('usualDesignation', new FormControl('', [Validators.required, Validators.maxLength(100)]));
		form.addControl('effectiveDate', new FormControl('', [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
		form.addControl('endValidityDate', new FormControl('', [Validators.required, Validators.pattern(BaseUploadComponent.DATE_REGEX_PATTERN)]));
	}

}
