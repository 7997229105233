<app-modular-form-control-layout [parent]="this">
	<select [id]="id()"
			class="form-select"
			[formControl]="formControl"
			[compareWith]="compareWith"
			[class.has-validation]="hasErrors()"
			[class.is-invalid]="hasErrors()">

		<option selected disabled [ngValue]="null">
			{{i18nPlaceholder()}}
		</option>

		<option *ngFor="let option of options" [ngValue]="option.value">
			{{ option.translate ? (option.label | translate) : option.label }}
		</option>
	</select>
</app-modular-form-control-layout>
