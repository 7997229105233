import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Injectable()
export class ModularFormsService {

	public readonly form: FormGroup = new FormGroup({});
	private i18nRoot: string;

	public withI18nRoot(root: string): void {
		this.i18nRoot = root;
	}

	get i18nPrefix(): string {
		return this.i18nRoot;
	}

	public getControl<T extends AbstractControl>(name: string): T {
		return this.form.get(name) as T;
	}

}
