<div class="d-flex flex-column p-5">

	<div class="d-flex flex-row mb-3 justify-content-between">
		<h1>{{'db.upload.viewer.title' | translate}}</h1>
	</div>

	<div class="container-xxl px-0 mx-0">

		<ng-container *ngIf="step === 'INPUT'">
			<app-db-upload-viewer-form (submitForm)="this.submit()"/>
		</ng-container>

		<ng-container *ngIf="step === 'PROCESSING'">
			<app-db-upload-progress (resetAll)="this.reset()"/>
		</ng-container>
	</div>

</div>
