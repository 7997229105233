import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Self} from '@angular/core';
import {ModularFormsService} from '../../shared/modular-forms/modular-forms.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {SelectOption} from '../../shared/modular-forms/_model/select-option';
import {Observable, shareReplay, Subscription} from 'rxjs';
import {mapArticleDescriptors, mapProjects, mapStatusToSelectOption} from '../../shared/modular-forms/_model/select-option.factory';
import {ProjectService} from '../../project/_service/project.service';
import {UserService} from '../../user/_service/user.service';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {CustomValidators} from '../../shared/validators/custom-validators';
import {ArticleService} from '../../article/_service/article.service';

@Component({
	selector: 'app-production-catalogue-form',
	templateUrl: './production-catalogue-form.component.html',
	providers: [ModularFormsService],
	exportAs: 'productioncatalogueForm'
})
export class ProductionCatalogueFormComponent implements AfterViewChecked, OnInit, OnDestroy {

	@Input()
	public productionCatalogue: ProductionCatalogue;

	@Input()
	public readonly = false;

	@Input()
	public copy: boolean;

	projects$: Observable<SelectOption[]>;
	articles$: Observable<SelectOption[]>;
	productionCatalogueStatuses: SelectOption[];
	oldStatus: SelectOption;

	private subscription = new Subscription();

	get form(): FormGroup {
		return this.formService.form;
	}

	constructor(
		@Self() protected readonly formService: ModularFormsService,
		private readonly projectService: ProjectService,
		private readonly userService: UserService,
		private articleService: ArticleService,
		private readonly changeDetectorRef: ChangeDetectorRef) {
		formService.withI18nRoot('production-catalogue.form');

		this.articles$ = this.articleService.findAll().pipe(
			mapArticleDescriptors(),
			shareReplay()
		);

		this.projects$ = this.projectService.getSelectableProjects().pipe(
			mapProjects(),
			shareReplay()
		);

		const form = formService.form;

		form.addControl('status', new FormControl<string>(''));
		form.addControl('approvalDate', new FormControl<string>(''));
		form.addControl('identification', new FormControl<string>('', [Validators.required]));
		form.addControl('airacCycle', new FormControl<string>('', [Validators.required, CustomValidators.validAirac]));
		form.addControl('requesterUser', new FormControl<string>({
			value: '',
			disabled: true
		}, [Validators.required]));
		form.addControl('requesterUserUuid', new FormControl<string>(null));
		form.addControl('project', new FormControl('', [Validators.required]));
		form.addControl('selectedArticles', new FormControl([], [Validators.required]));
		form.addControl('articles', new FormArray([]));
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnInit(): void {
		if (!this.productionCatalogue) {
			this.subscription.add(this.userService.getCurrentUser().subscribe(user => {
				this.form.get(('requesterUserUuid')).patchValue(user.uuid);
				this.form.get('requesterUser').patchValue(user.firstName + ' ' + user.lastName);
				this.form.get('requesterUser').updateValueAndValidity();
			}));

			this.subscription.add(this.projects$.subscribe((projects) => {
				if (projects.length === 1) {
					this.form.controls['project'].patchValue(projects[0].value);
				}
			}));

		} else {
			this.setProductionCatalogue(this.productionCatalogue);
		}

		if (this.readonly) {
			this.form.controls['approvalDate'].disable();
			this.form.controls['status'].disable();
			this.form.controls['identification'].disable();
			this.form.controls['airacCycle'].disable();
			this.form.controls['requesterUser'].disable();
			this.form.controls['selectedArticles'].disable();
			this.form.controls['project'].disable();
			this.form.controls['articles'].disable();
			this.form.updateValueAndValidity();
		}

		this.subscription.add(this.form.get('status').valueChanges.subscribe((value) => {
			if (value === 'READY_FOR_PRODUCTION') {
				const today = new Date();
				const day = String(today.getDate()).padStart(2, '0');
				const month = String(today.getMonth() + 1).padStart(2, '0');
				const year = today.getFullYear();
				this.form.controls['approvalDate'].patchValue(`${day}/${month}/${year}`);
			} else if (this.oldStatus?.value !== 'READY_FOR_PRODUCTION') {
				this.form.controls['approvalDate'].patchValue('');
			}
		}));
	}

	setProductionCatalogue(prodcat: ProductionCatalogue): void {
		this.productionCatalogue = prodcat;
		this.productionCatalogueStatuses = prodcat.status.nextStatuses.map(s => this.mapProductionCatalogueStatusToSelectOption(s));
		const status = this.mapProductionCatalogueStatusToSelectOption(prodcat.status.status);
		this.productionCatalogueStatuses.push(status);
		this.form.controls['status'].patchValue(status.value);
		this.oldStatus = status;
		this.form.controls['approvalDate'].patchValue(prodcat.approvalDate);
		this.form.controls['identification'].patchValue(prodcat.identification);
		this.form.controls['airacCycle'].patchValue(prodcat.airacCycle);
		this.form.controls['project'].patchValue(prodcat.project.uuid);
		this.form.controls['project'].updateValueAndValidity();
		this.form.controls['requesterUser'].patchValue(prodcat.requester.firstName + ' ' + prodcat.requester.lastName);
		this.form.controls['requesterUserUuid'].patchValue(prodcat.requester.uuid);
		this.form.controls['requesterUser'].updateValueAndValidity();
		if (prodcat.status.status !== 'REDACTION_IN_PROGRESS') {
			this.form.controls['approvalDate'].disable();
			this.form.controls['identification'].disable();
			this.form.controls['airacCycle'].disable();
			this.form.controls['project'].disable();
			this.form.controls['articles'].disable();
			this.form.controls['selectedArticles'].disable();
		}

		this.articleService.findAll().pipe(mapArticleDescriptors()).subscribe(articles => {
			const selectedArticles = prodcat.articles.map(article => articles.find(option => option.id === article.articleUuid));
			this.formService.getControl('selectedArticles').patchValue(selectedArticles);
			this.formService.getControl('selectedArticles').updateValueAndValidity();
		});
	}

	get informationKeyForCurrentState(): string {
		if (this.productionCatalogue.status.status == 'CANCELED') {
			return 'production-catalogue.form.not.editable.state.is.canceled';
		}
		return 'production-catalogue.form.not.editable.state.is.not.redaction.in.progress';
	}

	private mapProductionCatalogueStatusToSelectOption(status: string): SelectOption {
		return mapStatusToSelectOption(status, 'production-catalogue.status.');
	}
}
