import {BehaviorSubject, filter, from, map, mergeMap, Observable, Subscription, switchMap, tap} from 'rxjs';
import {UploadProgress} from './upload.progress';
import {GeneralUploadProgress} from './general.upload.progress';
import {UploadProgressStatus} from './upload.progress.status';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';

export abstract class BaseUploadComponent {
	public static readonly MAX_PARALLEL_UPLOADS = 3;
	public static readonly DATE_REGEX_PATTERN = '\\d{2}/\\d{2}/\\d{4}';
	public uploading = false;
	subscription = new Subscription();
	progresses: { [key: string]: BehaviorSubject<UploadProgress>; } = {};
	generalProgress = new BehaviorSubject<GeneralUploadProgress>(null);

	abstract doUploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>>;

	createEntity(uploadId: string): Observable<any> {
		return new BehaviorSubject(uploadId);
	}

	getProgresses(): BehaviorSubject<UploadProgress>[] {
		return Object.keys(this.progresses).map(key => this.progresses[key]);
	}

	upload(selectedFiles: File[]): void {
		selectedFiles.forEach(file => this.progresses[file.name] = new BehaviorSubject<UploadProgress>(new UploadProgress(file.name, UploadProgressStatus.WAITING)));

		const uploadUuid = uuidv4();
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.UPLOADING));

		let success = 0;

		this.subscription.add(from(Array.from(selectedFiles)).pipe(
			mergeMap(file => this.uploadFile(file, uploadUuid), BaseUploadComponent.MAX_PARALLEL_UPLOADS),
			tap(result => {
				if (result) {
					success++;
				}
			}),
			filter(() => success === selectedFiles.length),
			switchMap(() => this.createEntity(uploadUuid))
		).subscribe({
			next: () => this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.DONE)),
			error: (err) => {
				this.cancelFilesInStateWaiting();
				let errors = [];
				if (err.error) {
					if (err.error.composite) {
						errors = err.error.errors;
					} else {
						errors.push(err.error);
					}
				}
				this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.FAILED, errors));
			}
		}));
	}

	private uploadFile(file: File, uploadUuid: string): Observable<boolean> {
		return this.doUploadFile(file, uploadUuid)
			.pipe(
				tap((event: HttpEvent<any>) => this.handleHttpEvent(file, event)),
				filter((event: HttpEvent<any>) => event.type == HttpEventType.Response),
				map(() => true),
				tap({
					error: () => {
						const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.FAILED);
						this.progresses[file.name].next(uploadProgress);
					}
				})
			);
	}

	cancelFilesInStateWaiting(): void {
		this.getProgresses().forEach(subject => {
			const progress = subject.value;
			if (progress.status == UploadProgressStatus.WAITING) {
				this.progresses[progress.file].next(new UploadProgress(progress.file, UploadProgressStatus.CANCELLED));
			}
		});
	}

	handleHttpEvent(file: File, event: HttpEvent<any>): void {
		if (event.type === HttpEventType.UploadProgress) {
			const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.UPLOADING, event.total, event.loaded);
			this.progresses[file.name].next(uploadProgress);
		} else if (event.type === HttpEventType.Response) {
			const uploadProgress = new UploadProgress(file.name, UploadProgressStatus.DONE);
			this.progresses[file.name].next(uploadProgress);
		}
	}
}
