import {Component, Self} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {UploadBaseComponent} from '../upload-base/upload-base.component';

@Component({
	selector: 'app-db-upload-viewer',
	templateUrl: './upload-viewer.component.html',
	providers: [ModularFormsService]
})
export class UploadViewerComponent extends UploadBaseComponent {

	constructor(@Self() formService: ModularFormsService) {
		super(formService);
	}

}
