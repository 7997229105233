<app-modular-form-control-layout [parent]="this">
	<input [id]="id()"
		   class="form-control"
		   [placeholder]="i18nPlaceholder()"
		   [formControl]="formControl"
		   [class.has-validation]="hasErrors()"
		   [class.is-invalid]="hasErrors()"
		   ngbDatepicker
		   #datePicker="ngbDatepicker"
		   (click)="datePicker.toggle()">
</app-modular-form-control-layout>
