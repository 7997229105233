import {Component, EventEmitter, Output} from '@angular/core';
import {ModularFormsService} from '../../../../shared/modular-forms/modular-forms.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../shared/validators/custom-validators';

@Component({
	selector: 'app-db-upload-db-form',
	templateUrl: './upload-db-form.component.html'
})
export class UploadDbFormComponent {

	@Output() public submitForm = new EventEmitter();

	constructor(protected formService: ModularFormsService) {
		const form = this.formService.form;
		form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
	}

	public createFileFormGroup(file: File): FormGroup {
		const fileNameControl = new FormControl(file.name, [Validators.required]);
		fileNameControl.disable();

		return new FormGroup({
			fileName: fileNameControl,
			hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
			description: new FormControl('', [Validators.required, Validators.maxLength(255)])
		});
	}

	public submit(): void {
		this.submitForm.emit();
	}
}
