import {SelectOption} from './select-option';
import {Program} from '../../../notices/model/program';
import {map, OperatorFunction} from 'rxjs';
import {GeoAreaMap} from '../../../article/_model/geo-area-map';
import {Role} from '../../../role/role';
import {Customer} from '../../../customer/customer';
import {ArticleDescriptor} from '../../../article/_model/article-descriptor';
import {Project} from 'src/app/project/_model/project';
import {Folder} from '../../../notices/folder/folder';
import {RadicalIdentifier} from '../../../radicalidentifier/_model/radical-identifier';

export function mapPrograms(): OperatorFunction<Program[], SelectOption[]> {
	return map((programs: Program[]) => programs.map(program => ({
		value: program,
		label: program.name,
		id: program.uuid
	})));
}

export function mapGeoAreaTypes(): OperatorFunction<GeoAreaMap, SelectOption[]> {
	return map(geoAreaMap => Object.keys(geoAreaMap).map((type) => ({
		id: type,
		label: `geo-area.${type}.label`,
		translate: true,
		value: type
	})));
}

export function mapGeoAreas(): OperatorFunction<GeoAreaMap, { [key: string]: SelectOption[] }> {
	return map(geoAreaMap => {
		const result: { [key: string]: SelectOption[] } = {};
		for (const key in geoAreaMap) {
			result[key] = geoAreaMap[key].map(area => ({
				id: area,
				label: `geo-area.${key}.${area}.label`,
				translate: true,
				value: area
			}));
		}
		return result;
	});
}

export function mapRoles(): OperatorFunction<Role[], SelectOption[]> {
	return map(roles => roles.map(role => ({value: role, label: role.name, id: role.uuid})));
}

export function mapCustomers(): OperatorFunction<Customer[], SelectOption[]> {
	return map(customers => customers.map(customer => ({
		value: customer,
		label: customer.name,
		id: customer.uuid
	})));
}

export function mapArticles(articles: ArticleDescriptor[]): SelectOption[] {
	return articles.map(article => ({
		value: article.uuid,
		label: article.reference + (article.usualDesignation ? ` (${article.usualDesignation})` : ''),
		id: article.uuid
	}));
}

export function mapArticleDescriptors(): OperatorFunction<ArticleDescriptor[], SelectOption[]> {
	return map(articles => mapArticles(articles));
}

export function mapArticleDescriptorsWithoutUsualDesignation(): OperatorFunction<ArticleDescriptor[], SelectOption[]> {
	return map(articles => articles.map(article => ({
		value: article.uuid,
		label: article.reference,
		id: article.uuid
	})));
}

export function mapProjects(): OperatorFunction<Project[], SelectOption[]> {
	return map(projects => projects.map(project => ({
		value: project.uuid,
		label: project.name,
		id: project.uuid
	})));
}

export function mapFolders(): OperatorFunction<Folder[], SelectOption[]> {
	return map(folders => folders.map(folder => ({
		id: folder.uuid,
		value: folder.uuid,
		label: folder.name
	})));
}

export function mapRadicalIdentifiers(): OperatorFunction<RadicalIdentifier[], SelectOption[]> {
	return map(radicalIdentifiers => radicalIdentifiers.map(radicalIdentifier => ({
		id: radicalIdentifier.uuid,
		value: radicalIdentifier.uuid,
		label: radicalIdentifier.name
	})));
}

export function mapStatusToSelectOption(status: string, prefix: string): SelectOption {
	return {
		value: status,
		label: prefix + status,
		id: status,
		translate: true
	};
}

export function datasetFileTypeOptions(): SelectOption[] {
	return [
		{id: 'SOURCE_FILE', value: 'SOURCE_FILE', label: 'dataset.upload.form.files.fileType.options.source_file', translate: true},
		{id: 'SOC', value: 'SOC', label: 'dataset.upload.form.files.fileType.options.soc', translate: true},
		{id: 'EXCLUDE', value: 'EXCLUDE', label: 'dataset.upload.form.files.fileType.options.exclude', translate: true},
		{id: 'OTHER', value: 'OTHER', label: 'dataset.upload.form.files.fileType.options.other', translate: true}
	];

}

export function rnpArOptions(): SelectOption[] {
	return [
		{id: 'NO', value: 'NO', label: 'article.form.rnpAr.options.no', translate: true},
		{id: 'ZERO_THREE_ZERO_THREE', value: 'ZERO_THREE_ZERO_THREE', label: 'article.form.rnpAr.options.0_3_0_3', translate: true},
		{id: 'ZERO_THREE_ONE', value: 'ZERO_THREE_ONE', label: 'article.form.rnpAr.options.0_3_1', translate: true}
	];
}
export function exportControlOptions(): SelectOption[] {
	return [
		{id: 'NOT_CONTROLLED', value: 'NOT_CONTROLLED', label: 'article.form.exportControl.options.not_controlled', translate: true},
		{id: 'EAR_XX', value: 'EAR_XX', label: 'article.form.exportControl.options.ear_xx', translate: true},
		{id: 'EXPORT_CONTROLLED', value: 'EXPORT_CONTROLLED', label: 'article.form.exportControl.options.export_controlled', translate: true}
	];
}

