import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {Observable} from 'rxjs';
import {UploadProgressStatus} from '../../../shared/upload/upload.progress.status';
import {GeneralUploadProgress} from '../../../shared/upload/general.upload.progress';
import {NoticeService} from '../../_service/notice.service';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {HttpEvent} from '@angular/common/http';

@Component({
	selector: 'app-upload-notice-progress',
	templateUrl: './upload-notice-progress.component.html'
})
export class UploadNoticeProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Output() public resetAll = new EventEmitter();

	constructor(private formService: ModularFormsService,
				private noticeService: NoticeService) {
		super();
	}

	ngOnInit(): void {
		const files = this.formService.getControl('actualFiles').value;
		this.upload(files);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	override doUploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		return this.noticeService.uploadFile(file, uploadUuid);
	}

	override createEntity(uploadUuid: string): Observable<any> {
		this.generalProgress.next(new GeneralUploadProgress(UploadProgressStatus.PROCESSING));

		const form = this.formService.form;
		const request = {...form.getRawValue()};
		delete request['actualFiles'];

		if (request['makeAvailableFor'] === 'CUSTOMERS') {
			request['customerUuids'] = request['availableCustomerUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'PROGRAMS') {
			request['programUuids'] = request['availableProgramUuids'].map((option: SelectOption) => option.id);
		} else if (request['makeAvailableFor'] === 'ALL_CUSTOMERS') {
			request['allCustomers'] = true;
		}

		delete request['makeAvailableFor'];
		delete request['availableCustomerUuids'];
		delete request['availableProgramUuids'];

		return this.noticeService.createNotice(request, uploadUuid);
	}

}
