import {Component, EventEmitter, Output} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'app-fms-upload-form',
	templateUrl: './fms-upload-form.component.html'
})
export class FmsUploadFormComponent {

	@Output() public submitForm = new EventEmitter();

	constructor(protected formsService: ModularFormsService) {
		const form = formsService.form;
		form.addControl('files', new FormControl([]));
	}

	public submit(): void {
		this.submitForm.emit();
	}
}
