import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ModularFormControl} from '../modular-form-control/modular-form-control';
import {ModularFormControlInputWithLayout} from './modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-layout[parent]',
	templateUrl: './modular-form-control-layout.component.html',
	styleUrls: ['./modular-from-control-layout.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class ModularFormControlLayoutComponent extends ModularFormControl implements OnInit {

	@Input() public parent: ModularFormControlInputWithLayout;

	override ngOnInit(): void {
		this.name = this.parent.name;
		this.i18nPrefix = this.parent.i18nPrefix;

		super.ngOnInit();
	}

}
