import {Component, Self} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {UploadStep} from '../../../shared/upload/upload-step';

@Component({
	selector: 'app-upload-notice',
	templateUrl: './upload-notice.component.html',
	providers: [ModularFormsService]
})
export class UploadNoticeComponent {

	step: UploadStep = 'INPUT';

	constructor(@Self() private formService: ModularFormsService) {
		this.formService.withI18nRoot('notice.upload.form');
	}

	public submit(): void {
		this.step = 'PROCESSING';
	}

	public reset(): void {
		this.step = 'INPUT';
		this.formService.form.reset();
	}

}
