import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {ModularFormsService} from '../../../../shared/modular-forms/modular-forms.service';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ZipFileService} from '../../../_service/zip-file.service';
import {CustomValidators} from '../../../../shared/validators/custom-validators';

@Component({
	selector: 'app-db-upload-viewer-form',
	templateUrl: './upload-viewer-form.component.html'
})
export class UploadViewerFormComponent implements OnDestroy {

	@Output() public submitForm = new EventEmitter();

	private subscription = new Subscription();

	constructor(protected formService: ModularFormsService,
				private zipFileService: ZipFileService) {
		const form = this.formService.form;
		form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(): (file: File) => FormGroup {
		return (file: File) => {
			const fileNameControl = new FormControl(file.name, [Validators.required]);
			fileNameControl.disable();

			const tsvFiles = new FormArray([]);
			this.subscription.add(this.zipFileService.getEntries(file).subscribe(filename =>
				this.addTsvFileGroup(filename, tsvFiles)));

			return new FormGroup({
				fileName: fileNameControl,
				hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
				tsvFiles: tsvFiles
			});
		};
	}

	private addTsvFileGroup(filename: string, tsvFiles: FormArray): void {
		const fileNameControl = new FormControl(filename, [Validators.required]);
		fileNameControl.disable();

		const path = filename.split('/');
		const suggestedDisplayName = path[path.length - 1]
			.slice(0, -4).replaceAll(/_|\s{2}/g, ' ').trim();

		tsvFiles.controls.push(new FormGroup({
			fileName: fileNameControl,
			displayName: new FormControl(suggestedDisplayName, [Validators.required])
		}));
	}

	public getTsvFileControls(index: number): AbstractControl[] {
		return this.formService.getControl<FormArray>('tsvFiles.' + index + '.tsvFiles').controls;
	}

	public submit(): void {
		this.submitForm.emit();
	}

}
