import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogueService} from '../_service/production-catalogue.service';

@Component({
	selector: 'app-production-catalogue-characteristics',
	templateUrl: './view-production-catalogue-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewProductionCatalogueModalComponent {

	@Input() productionCatalogue: ProductionCatalogue;

	@Output()
	public closePage: EventEmitter<void> = new EventEmitter();

	@Output()
	public editCatalogue: EventEmitter<string> = new EventEmitter();

	@Output()
	public deleteCatalogue: EventEmitter<string> = new EventEmitter();

	constructor(protected translateService: TranslateService,
				private productionCatalogueService: ProductionCatalogueService) {
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('production-catalogue.delete.confirmation-message.are-you-sure')}</p>`;
	}

	confirmDelete(uuid: string): void {
		this.deleteCatalogue.emit(uuid);
	}

	exportProductionCatalogue(): void {
		this.productionCatalogueService.downloadExport(this.productionCatalogue.uuid, this.productionCatalogue.identification);
	}
}
