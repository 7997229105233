<app-modular-form-control-input name="articleReference" layout="flex"/>

<app-modular-form-control-input name="databaseName" layout="flex"/>

<app-modular-form-control-input name="airacCycle" layout="flex"/>

<app-modular-form-control-input name="releaseNumber" layout="flex"/>

<app-modular-form-control-input name="usualDesignation" layout="flex"/>

<app-modular-form-control-date name="effectiveDate" layout="flex"/>

<app-modular-form-control-date name="endValidityDate" layout="flex"/>
