import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpParams, HttpResponse} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Page} from '../../shared/pagination/page';
import {FlightDatabase} from '../_model/flight-database';
import {CreateFlightDatabaseRequest} from '../_model/create-flight-database-request';
import {TsvLine} from '../_model/tsv-line';
import {TsvDiffLine} from '../_model/tsv-diff-line';
import {Content} from '../../shared/model/content';
import {FilterCriteria} from '../../shared/model/filter-criteria';
import {ContentArray} from '../../shared/model/content-array';
import {DownloadEvent} from './download-event';

@Injectable()
export class FlightDatabaseService {

	readonly baseUrl = environment.backend_url + '/database';

	constructor(private http: HttpClient) {
	}

	uploadFile(file: File, uploadUuid: string): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('uploadUuid', uploadUuid);
		return this.http.post(`${this.baseUrl}/upload`, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}

	createDatabase(request: CreateFlightDatabaseRequest, uploadUuid: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/create`, request, {params: {'uploadUuid': uploadUuid}});
	}

	search(pageNumber: number, contentSearchCriteria: ContentArray, searchValue?: string): Observable<Page<FlightDatabase>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue != undefined) {
			params = params.append('search.usualDesignation', searchValue);
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get<Page<FlightDatabase>>(this.baseUrl + '/search', {params});
	}

	export(contentSearchCriteria: ContentArray): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get(this.baseUrl + '/export', {observe: 'response', responseType: 'blob', params});
	}

	exportTsv(flightDatabaseUuid: string, flightDatabaseTsvFileUuid: string, contentSearchCriteria: ContentArray): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get(this.baseUrl + `/${flightDatabaseUuid}/tsvfile/${flightDatabaseTsvFileUuid}/export`, {
			observe: 'response',
			responseType: 'blob',
			params
		});
	}

	exportTsvDiffLines(flightDatabaseUuid: string, flightDatabaseTsvFileUuid: string, contentSearchCriteria: ContentArray): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get(this.baseUrl + `/${flightDatabaseUuid}/tsvfile/${flightDatabaseTsvFileUuid}/export-diff-lines`, {
			observe: 'response',
			responseType: 'blob',
			params
		});
	}

	flagDatabaseAsError(uuid: string): Observable<FlightDatabase> {
		return this.http.put<FlightDatabase>(`${this.baseUrl}/${uuid}/actions/flag`, null);
	}

	unflagDatabaseAsError(uuid: string): Observable<FlightDatabase> {
		return this.http.put<FlightDatabase>(`${this.baseUrl}/${uuid}/actions/unflag`, null);
	}

	downloadFileStream(uuid: string, fileUuid: string): Observable<DownloadEvent> {
		return this.http.get(`${this.baseUrl}/download/${uuid}/${fileUuid}/stream`, {
			reportProgress: true,
			observe: 'events',
			responseType: 'blob'
		}).pipe(map(event => {
			if (event && event.type === HttpEventType.DownloadProgress) {
				return {
					progress: Math.round((100 * event.loaded) / event.total),
					state: 'IN_PROGRESS',
					content: null
				};
			} else if (event && event.type === HttpEventType.Response) {
				return {
					progress: 100,
					state: 'DONE',
					content: event.body
				};
			} else {
				return {
					progress: 0,
					state: 'PENDING',
					content: null
				};
			}
		}));
	}

	getAllDatabasesForViewer(): Observable<FlightDatabase[]> {
		return this.http.get<FlightDatabase[]>(`${this.baseUrl}/viewer/all`);
	}

	searchTsvLines(pageNumber: number, flightDatabaseUuid: string, flightDatabaseTsvFileUuid: string, contentSearchCriteria: Content): Observable<Page<TsvLine>> {
		const requestParams = {
			'page': pageNumber
		} as any;
		for (const parameter in contentSearchCriteria) {
			if (contentSearchCriteria[parameter] !== '') {
				requestParams['search.' + parameter] = contentSearchCriteria[parameter];
			}
		}
		return this.http.get<Page<TsvLine>>(this.baseUrl + `/${flightDatabaseUuid}/tsvfile/${flightDatabaseTsvFileUuid}/search-lines`, {params: requestParams});
	}

	searchTsvDiffLines(pageNumber: number, flightDatabaseUuid: string, flightDatabaseTsvFileUuid: string, updatesOnly: boolean, contentSearchCriteria: Content): Observable<Page<TsvDiffLine>> {
		const requestParams = {
			'page': pageNumber,
			'updates-only': updatesOnly
		} as any;
		for (const parameter in contentSearchCriteria) {
			if (contentSearchCriteria[parameter] !== '') {
				requestParams['search.' + parameter] = contentSearchCriteria[parameter];
			}
		}

		return this.http.get<Page<TsvDiffLine>>(this.baseUrl + `/${flightDatabaseUuid}/tsvfile/${flightDatabaseTsvFileUuid}/search-diff-lines`, {
			params: requestParams
		});
	}

	logViewerConsults(navDB: string, section: string): Observable<void> {
		return this.http.get<void>(this.baseUrl + `/viewer/log/${navDB}/${section}`);
	}

	logViewerUpdatesConsults(navDB: string, section: string): Observable<void> {
		return this.http.get<void>(this.baseUrl + `/viewer/log/updates/${navDB}/${section}`);
	}

	logDbDownloadClientSideError(navDB: string, fileUuid: string, error: any): Observable<void> {
		return this.http.post<void>(this.baseUrl + `/download/${navDB}/${fileUuid}/log/error`, JSON.stringify(error));
	}

	deleteDatabase(uuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${uuid}`);
	}

	getFilterCriteria(): Observable<FilterCriteria[]> {
		return this.http.get<FilterCriteria[]>(`${this.baseUrl}/filtercriteria`);
	}

	getLinkedFlightDatabases(articleUuid: string): Observable<FlightDatabase[]> {
		return this.http.get<FlightDatabase[]>(`${this.baseUrl}/linked-to-article/${articleUuid}`);
	}

}
