import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {DatasetService} from '../_service/dataset.service';
import {DatasetOverview} from '../_model/dataset-overview';

@Component({
	selector: 'app-dataset-overview',
	templateUrl: './dataset-overview.component.html'
})
export class DatasetOverviewComponent implements OnInit, OnDestroy {

	page$: Observable<Page<DatasetOverview>>;
	headers: Header[] = [
		{name: 'radical-identifier'},
		{name: 'identifier'},
		{name: 'usual-designation'},
		{name: 'airac-cycle'},
		{name: 'release-number'},
		{name: 'upload-date'},
		{name: 'nr-of-files'}

	];
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private datasetService: DatasetService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.datasetService.search(currentPage - 1, null))
			);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}
}
