import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Self} from '@angular/core';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../shared/validators/custom-validators';
import {Observable, shareReplay, Subscription} from 'rxjs';
import {CreateOrUpdateDatasetRequest} from '../../_model/create-or-update-dataset-request';
import {CreateDatasetFileData} from '../../_model/create-dataset-file-data';
import {UploadFormResult} from './upload-form-result';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {RadicalIdentifierService} from '../../../radicalidentifier/_service/radical-identifier.service';
import {datasetFileTypeOptions, mapRadicalIdentifiers} from '../../../shared/modular-forms/_model/select-option.factory';

@Component({
	selector: 'app-dataset-upload-form',
	templateUrl: './dataset-upload-form.component.html',
	providers: [ModularFormsService]
})
export class DatasetUploadFormComponent implements OnInit, OnDestroy {

	@Output()
	public submitForm = new EventEmitter<UploadFormResult>();

	@Input() reset$: Observable<void>;

	radicalIdentifiers$: Observable<SelectOption[]>;
	datasetFileTypes: SelectOption[];

	private subscription = new Subscription();

	constructor(@Self() protected formService: ModularFormsService,
				private radicalIdentifierService: RadicalIdentifierService) {
		const form = this.formService.form;
		this.formService.withI18nRoot('dataset.upload.form');

		form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(100)]));
		form.addControl('datasetIdentifier', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		form.addControl('airacCycle', new FormControl('', [Validators.required, CustomValidators.validAirac]));
		form.addControl('releaseNumber', new FormControl(0, [Validators.required, Validators.min(0)]));
		form.addControl('radicalIdentifierUuid', new FormControl('', [Validators.required]));
		form.addControl('actualFiles', new FormControl([], [CustomValidators.requireNonEmptyArray]));
		form.addControl('files', new FormArray([]));

		this.radicalIdentifiers$ = this.radicalIdentifierService.findAll().pipe(
			mapRadicalIdentifiers(),
			shareReplay()
		);

		this.datasetFileTypes = datasetFileTypeOptions();
	}

	ngOnInit(): void {
		this.subscription.add(this.reset$.subscribe(() => this.formService.form.reset()));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public createFileFormGroup(file: File): FormGroup {
		const fileNameControl = new FormControl(file.name, [Validators.required]);
		fileNameControl.disable();

		return new FormGroup({
			fileName: fileNameControl,
			hash: new FormControl('', [Validators.required, Validators.maxLength(255)]),
			fileType: new FormControl('', [Validators.required])
		});
	}

	public submit(): void {
		this.submitForm.emit({
			request: this.createRequest(),
			selectedFiles: this.formService.getControl('actualFiles').value
		});
	}

	public createRequest(): CreateOrUpdateDatasetRequest {
		const form = this.formService.form;
		const files = this.extractFiles(form);
		return {
			usualDesignation: form.get('usualDesignation').value,
			identifier: form.get('datasetIdentifier').value,
			radicalIdentifierUuid: form.get('radicalIdentifierUuid').value,
			airacCycle: form.get('airacCycle').value,
			releaseNumber: +form.get('releaseNumber').value,
			files: files
		};
	}

	private extractFiles(form: FormGroup<any>): CreateDatasetFileData[] {
		const filesControls = form.get('files') as FormArray;
		return filesControls.controls.map(controls => controls.getRawValue()).map(values => {
			return {
				fileName: values.fileName,
				hash: values.hash,
				fileType: values.fileType
			} as CreateDatasetFileData;
		});
	}
}
