import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseUploadComponent} from '../../../shared/upload/base-upload.component';
import {FlightManagementSystemService} from '../../_service/flight-management-system.service';
import {Observable} from 'rxjs';
import {HttpEvent} from '@angular/common/http';
import {ModularFormsService} from '../../../shared/modular-forms/modular-forms.service';

@Component({
	selector: 'app-fms-upload-progress',
	templateUrl: './fms-upload-progress.component.html'
})
export class FmsUploadProgressComponent extends BaseUploadComponent implements OnInit, OnDestroy {

	@Output() public resetAll = new EventEmitter();

	constructor(private formsService: ModularFormsService,
				private fmsService: FlightManagementSystemService) {
		super();
	}

	ngOnInit(): void {
		const files = this.formsService.getControl('files').value;
		this.upload(files);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reset(): void {
		this.resetAll.emit();
	}

	override doUploadFile(file: File): Observable<HttpEvent<any>> {
		return this.fmsService.upload(file);
	}

}
